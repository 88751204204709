// title: What is a Magic the Gathering: Cube - Lucky Paper
// frameCardName: Doubling Cube
// frameCardSet: 10E
// lastUpdatedDate: 2021-08-14

import * as styles from './what-is-a-cube.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import * as Typography from 'components/typography'

interface Props {
  pageContext: any
}

const WhatIsACubePage: React.FC<Props> = (props) => {
  return (
    <Layout
      {...props.pageContext}
      accentColor="#0562F3"
      includeNewsletterSignup
    >
      <article className={styles.container}>
        <h1 className={styles.heading}>
          <Typography.PrimaryHeading>
            Magic: the Gathering Cube
          </Typography.PrimaryHeading>
        </h1>

        <div className={styles.intro}>
          <Typography.Paragraph>
            Cube is a custom draft format of Magic: the Gathering. Traditionally
            cubes are sets of 360 or more unique cards chosen by the Cube’s
            designer, drafted like a booster draft. There are no hard rules.
            Within and beyond that template, Cube can be whatever you like.
          </Typography.Paragraph>
        </div>

        <Typography.SecondaryDivider />

        <Typography.Paragraph>
          Many Cubes, including the well known{' '}
          <a href="https://cubecobra.com/cube/overview/5d2cb3f44153591614458e5d">
            Vintage
          </a>{' '}
          and{' '}
          <a href="https://cubecobra.com/cube/overview/5dd483199722167e41af4a66">
            Legacy
          </a>{' '}
          cubes on Magic the Gathering Online, feature powerful cards from the
          history of the game. These formats allow a unique opportunity to play
          with cards that simply can’t be played or drafted anywhere else.
        </Typography.Paragraph>

        <Typography.Paragraph>
          Cube can equally be an opportunity to utilize cards that were never
          viable in other formats, providing a space to make them competitively
          viable. Designers often use{' '}
          <a href="https://cubecobra.com/cube/overview/5d617ac6c2a85f3b75fe95a4">
            rarity
          </a>
          ,{' '}
          <a href="https://cubecobra.com/cube/overview/5d2cdf280442c316b0ef86c8">
            budget
          </a>
          , or{' '}
          <a href="https://cubecobra.com/cube/overview/5dc09316845516168633e492">
            power level
          </a>{' '}
          restrictions to support and balance the draft experience they’re
          looking for.
        </Typography.Paragraph>

        <Typography.Paragraph>
          Cube can even be a space for players to explore the depth and{' '}
          <Link to="/resources/cube-map/">bizarre extremes</Link> of the game.
          Designers create cubes centered on{' '}
          <a href="https://cubecobra.com/cube/overview/5d87ae20d5242a0b00e3205c">
            multiplayer gameplay
          </a>
          ,{' '}
          <a href="https://cubecobra.com/cube/overview/5d8978207836a91c43c8e3cf">
            Commander/EDH
          </a>
          ,{' '}
          <a href="https://cubecobra.com/cube/overview/5e42d7b89814f17ed8535209">
            silver-bordered cards
          </a>
          ,{' '}
          <Link to="/articles/an-introduction-to-the-degenerate-micro-cube/">
            dramatic alterations
          </Link>{' '}
          of Magic’s rules,{' '}
          <a href="https://cubecobra.com/cube/overview/5dc9a64553f3101ce8e1d08c">
            wild stipulations
          </a>{' '}
          on cards to include, and even{' '}
          <a href="https://cubecobra.com/cube/overview/5f5f3ea4bba0da104f478ddc">
            custom cards
          </a>
          .
        </Typography.Paragraph>

        <Typography.Paragraph>
          More than anything else, Cube is a true playground for lovers of Magic
          where players can craft any experience they can imagine without the
          limitations of ban-lists, format restrictions, power creep, play group
          arms races, or needing to optimize against a meta game.
        </Typography.Paragraph>

        <Typography.Divider />
      </article>
    </Layout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'What is a Magic the Gathering Cube - Lucky Paper',
        description:
          'Cube is a custom draft format of Magic: the Gathering. Traditionally cubes are sets of 360 or more unique cards chosen by the Cube’s designer, drafted like a booster draft. There are no hard rules. Within and beyond that template, Cube can be whatever you like.',
      }}
    />
  )
}

export default WhatIsACubePage
